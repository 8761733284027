$mondrian-red: #f70f0f;
$mondrian-yellow: #fce317;
$mondrian-blue: #0f7fbf;
$mondrian-blue-shade: #0d6ca2;

$flat-green: #27ae60;
$flat-green-shade: #219452;

$apple-red: #fa6058;
$apple-red-accent: #db4839;
$apple-yellow: #fcbd2e;
$apple-yellow-accent: #efb42c;
$apple-green: #30ca41;
$apple-green-accent: #2ec03e;
$apple-dark: #303133;
$apple-dark-accent-opposite: #1d1e20;
$apple-dark-accent: #6c6c6c;
$apple-light: #e7e6e7;
$apple-light-accent: #c0bfc0;

$windows-dark: #43403c;
$windows-dark-accent: #242120;
$windows-light: #f2f2f2;
$windows-light-accent: #ccc;

$off-black: #222;
$light-black: #333;
$off-white: #fefefe;
$disabled-background: #ddd;
$disabled-text: #666;
$light-blue-gray: #e1e6ec;
