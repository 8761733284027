.iphone-x-new {
  flex: 0 0 auto;
  position: relative;
  width: 375px;
  height: 812px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
  margin: 40px;

  .screen {
    overflow: hidden;
    border-radius: 40px;
    height: 100%;
    display: flex;
    align-items: flex-end;

    img {
      z-index: 1;
    }
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    z-index: 1;

    .header-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 39px;

      .lock-icon {
        height: 10px;
        width: 10px;
        margin-right: 5px;
      }

      .header-text {
        font-size: 13px;
        line-height: 1;
      }
    }

    &.dark {
      background-color: #353739;
      color: #e8eaed;
    }

    &.light {
      background-color: #fff;
      color: #202124;
      border-bottom: 1px solid #ccc;
    }
  }

  .sleep,
  .volume,
  .volume::before,
  .volume::after {
    width: 3px;
    background: #333;
    position: absolute;
  }

  .volume,
  .volume::before,
  .volume::after {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
  }

  .sleep {
    height: 96px;
    top: 196px;
    right: -23px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
  }

  .volume {
    left: -23px;
    top: 116px;
    height: 32px;

    &::before {
      height: 62px;
      top: 62px;
      content: '';
      left: 0;
    }

    &::after {
      height: 62px;
      top: 140px;
      content: '';
      left: 0;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // home button indicator
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }

  // frontal camera/speaker frame
  &:before {
    top: -1px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
    z-index: 2;
  }

  .speaker,
  .camera {
    position: absolute;
    display: block;
    color: transparent;
    z-index: 3;
  }

  // speaker
  .speaker {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }

  // camera
  .camera {
    left: 10%;
    top: 0px;
    transform: translate(190px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);

    &:after {
      content: '';
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    }
  }
}
